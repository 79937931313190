'use client';

import { useCallback, useEffect, useState } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';
import dynamic from 'next/dynamic';

import { activeLeadFormAtom } from '@core/Atoms/Leads/ActiveLeadForm.atom';
import { countryCodesAtom } from '@core/Atoms/Leads/CountryCode.atom';

import { DE_LOCALE } from '@core/Constants/Locale.constants';

import { LeadFormProps } from '../../Entities/LeadForm';
import { getCountryCodes } from '../../Utils/GetCountryCodes';

const LoadingModal = dynamic(
  () => import('./StatusDisplays/LoadingModal').then(mod => mod.LoadingModal),
  {
    ssr: false,
  },
);

const SuccessModal = dynamic(
  () => import('./StatusDisplays/SuccessModal').then(mod => mod.SuccessModal),
  {
    ssr: false,
  },
);

const ErrorModal = dynamic(
  () => import('./StatusDisplays/ErrorModal').then(mod => mod.ErrorModal),
  {
    ssr: false,
  },
);

const MessageDealerLeadForm = dynamic(
  () => import('./LeadForms/MessageDealerLeadForm').then(mod => mod.MessageDealerLeadForm),
  { ssr: false },
);

const MessageDealerFinanceLeadForm = dynamic(
  () =>
    import('./LeadForms/MessageDealerFinanceLeadForm').then(
      mod => mod.MessageDealerFinanceLeadForm,
    ),
  { ssr: false },
);

const RequestCallbackLeadForm = dynamic(
  () => import('./LeadForms/RequestCallbackLeadForm').then(mod => mod.RequestCallbackLeadForm),
  { ssr: false },
);

const RequestVideoLeadForm = dynamic(
  () => import('./LeadForms/RequestVideoLeadForm').then(mod => mod.RequestVideoLeadForm),
  { ssr: false },
);

const RequestImageLeadForm = dynamic(
  () => import('./LeadForms/RequestImageLeadForm').then(mod => mod.RequestImageLeadForm),
  { ssr: false },
);

const HistoryCheckLeadForm = dynamic(
  () => import('./LeadForms/HistoryCheckLeadForm').then(mod => mod.HistoryCheckLeadForm),
  { ssr: false },
);

const LeasingOnlineLeadForm = dynamic(
  () => import('./LeadForms/LeasingOnlineLeadForm').then(mod => mod.LeasingOnlineLeadForm),
  { ssr: false },
);

const BookAppointmentLeadForm = dynamic(
  () => import('./LeadForms/BookAppointmentLeadForm').then(mod => mod.BookAppointmentLeadForm),
  { ssr: false },
);

export const LeadFormModals = ({ strings, locale }: LeadFormProps) => {
  const activeLeadForm = useAtomValue(activeLeadFormAtom);

  const [openedLeadForms, setOpenedLeadForms] = useState<string[]>([]);
  const setCountryCodes = useSetAtom(countryCodesAtom);

  const fetchCountryCodes = useCallback(async () => {
    const countryCodes = await getCountryCodes();
    setCountryCodes(countryCodes);
  }, [setCountryCodes]);

  useEffect(() => {
    if (activeLeadForm && !openedLeadForms.includes(activeLeadForm)) {
      setOpenedLeadForms(prev => [...prev, activeLeadForm]);
      if (locale === DE_LOCALE) fetchCountryCodes();
    }
  }, [activeLeadForm, openedLeadForms]);

  const leadFormComponents: Record<string, React.ComponentType<any>> = {
    'loading-modal': LoadingModal,
    'success-modal': SuccessModal,
    'error-modal': ErrorModal,
    'message-dealer': MessageDealerLeadForm,
    'message-dealer-finance': MessageDealerFinanceLeadForm,
    'request-callback': RequestCallbackLeadForm,
    'request-video': RequestVideoLeadForm,
    'request-images': RequestImageLeadForm,
    'history-check': HistoryCheckLeadForm,
    'leasing-online': LeasingOnlineLeadForm,
    'book-appointment': BookAppointmentLeadForm,
  };

  return (
    <>
      {openedLeadForms.map(leadFormId => {
        const Component = leadFormComponents[leadFormId];
        if (activeLeadForm !== leadFormId) return null;
        if (!Component) return null;
        return (
          <Component key={leadFormId} strings={strings} locale={locale} data-test-id="modal" />
        );
      })}
    </>
  );
};
